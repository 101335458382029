import React from "react"
import { LinkButton } from "../components/button"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import * as theme from "../theme"
import aWalkThroughTimeCover from "./a-walk-through-time-cover.jpg"
import pilgrimageCover from "./pilgrimage-cover.jpg"
import howDidTheyBuildThatCover from "./how-did-they-build-that-cover.jpg"
import theCathedralCover from "./the-cathedral-cover.jpg"
import LearnHeader from "./learn-header"
import Layout from "../layout"

const SectionContainer = styled.article`
  display: flex;
  padding: 5em 2em;
  flex-wrap: wrap;
  border-bottom: 0.1em solid ${theme.colors.neutral.light};
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    flex: 0 1 15em;
    margin: 0 3em;
    width: 15em;
  }

  div {
    font-family: ${theme.fonts.sans};
    flex: 1 1 0;
    max-width: 30em;
    padding: 0 2em;

    a {
      margin-top: 1em;
    }
  }
`

export default () => (
  <Layout>
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <LearnHeader />
      <div
        css={css`
          background: ${theme.colors.neutral.pastel};
          margin: 0;
          padding: 3em 5em;
          font-family: ${theme.fonts.sans};
          font-size: 1.1em;

          @media(max-width: 40rem) {
            padding: 2rem;
          }
        `}
      >
        <h3>Learning Resources</h3>
        <p>
          These pages offer curriculum-linked, interactive resources and activities for learning in the classroom. They are also an invaluable resource for preparing for and supporting a school visit to Canterbury Cathedral.
        </p>
        <p>
          For more information about schools visits, please contact <a href="mailto:schools@canterbury-cathedral.org">schools@canterbury-cathedral.org</a>
        </p>
        <p>
          These resources can also be used alongside our Loan Box scheme. Loan Boxes and outreach sessions are available to schools and community groups in all areas. In times of high demand, priority for loan boxes and outreach will be given to requests from: Swale, Shepway, Thanet and Canterbury.
        </p>
        <p>
          In addition we offer outreach sessions to schools in these areas.
        </p>
      </div>

      <SectionContainer>
        <img src={theCathedralCover} alt="The Cathedral" />
        <div>
          <h2>The Cathedral</h2>
          <p>
            Canterbury Cathedral is a sacred and holy place. It is the mother church of the Worldwide Anglican Communion and the seat of the Archbishop of Canterbury. Find out about the different spaces within this extraordinary building and how its purpose as a place of worship has shaped the building we see today.
          </p>
          <LinkButton to="/the-cathedral">Open resource</LinkButton>
        </div>
      </SectionContainer>

      <SectionContainer>
        <img src={pilgrimageCover} alt="Pilgrimage" />
        <div>
          <h2>Pilgrimage</h2>
          <p>
            Pilgrimage has formed a key part of the life of Canterbury Cathedral since the martyrdom of Archbishop Thomas Becket in 1170. Pilgrims have shaped the Cathedral building that stands today and every year we still welcome thousands of people both at that the start and end of their spiritual journey.
          </p>
          <LinkButton to="/pilgrimage">Open resource</LinkButton>
        </div>
      </SectionContainer>
      <SectionContainer>
        <img src={aWalkThroughTimeCover} alt="A walk through time" />
        <div>
          <h2>A walk through time</h2>
          <p>
            An interactive resource, focused around a timeline of key events in the history of Canterbury Cathedral from its Saxon origins in 597 to the present day. Find out about the people and events that have shaped the history both of this Cathedral and the whole country.
          </p>
          <LinkButton to="/a-walk-through-time">Open resource</LinkButton>
        </div>
      </SectionContainer>
      <SectionContainer disabled={false}>
        <img src={howDidTheyBuildThatCover} alt="How did they build that?" />
        <div>
          <h2>How did they build that?</h2>
          <p>
            An exploration of the building styles, techniques and materials used in the construction of this inspirational building. Discover how the stone and stained glass were crafted over time and how they are conserved in the present day.
          </p>
          <LinkButton to="/how-did-they-build-that">Open resource</LinkButton>
        </div>
      </SectionContainer>
    </div>
  </Layout>
)
